<template>
  <div class="forumPage">
    <div class="app h5MaxWidth">
      <div class="subject">
        <div class="pageHead display alignCenter">
          <div class="pageTitle flex1">
            <img src="./../images/pageTitle01.svg" alt="" />
          </div>
          <div class="openRule"></div>
        </div>
      </div>
      <div class="main">
        <div class="window">
          <div class="newtitle">
            <div>{{ info.title }}</div>
          </div>
          <div class="text-container" v-html="info.content"></div>
        </div>
        <div class="list" id="list01">
          <ul>
            <li class="radius15PX" v-for="item in dataList" :key="item.id">
              <div>
                <div class="userCard display alignCenter">
                  <div class="portrait display alignCenter justifyCenter">
                    <div
                      class="frame radius500PX display alignCenter justifyCenter"
                    >
                      <img :src="item.avatar" />
                    </div>
                  </div>
                  <div class="infor flex1">
                    <p>{{ item.username }}</p>
                    <span>{{
                      (item.create_time * 1000) | formatTime("YMDHMS")
                    }}</span>
                  </div>
                </div>
                <div class="commentContent">
                  <div class="utterance">
                    <p>{{ item.remark }}</p>
                  </div>
                  <div class="photoGroup display alignStart alignStretch">
                    <div class="flex1">
                      <img
                        :src="x"
                        alt=""
                        v-for="x in item.thumbs"
                        :key="x"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="publishEnter" @click="goPage('/publish')">
          <div class="icon radius500PX display alignCenter justifyCenter">
            <i class="iconfont icon-pay-by-check1"></i>
          </div>
          <p>Publish</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/utils/config.js";
export default {
  data() {
    return {
      config,
      dataList: [],
      info: {},
    };
  },
  components: {},
  methods: {
    goPage(page) {
      this.$router.push(page);
    },
  },
  mounted() {
    this.getAjax("api/income/list", {}, "get").then((res) => {
      this.dataList = res.data;
    });
    this.getAjax(
      "api/login/income",
      {
        code: "",
        data: "",
      },
      "post"
    ).then((res) => {
      this.info = res.data;
    });
  },
};
</script>

<style scoped lang="less">
.window {
  position: relative;
  background: #fff;
  border-radius: 15px;
  width: 95%;
  min-height: 100px;
  margin: 10px auto;
  .newtitle {
    text-align: center;
    padding-top: 8px;
    font-weight: 700;
    font-size: 18px;
  }
  .text-container {
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: pre-wrap;
    padding: 10px;
  }
}
.skeleton .sketch {
  margin: 10px 0 0;
}

.userCard .portrait .vip_ICON {
  width: 20px;
  height: 20px;
  right: -3px;
  bottom: -2px;
  position: absolute;
}

.forumPage .main {
  top: -50px;
  position: relative;
}

.forumPage .openRule .title p {
  font-weight: 700;
  font-size: 16px;
}

.forumPage .tips {
  background-color: transparent;
}

.forumPage .list {
  min-height: 80px;
  margin: 5px;
}

.forumPage .list ul {
  min-height: 20px;
}

.forumPage .list ul > li {
  margin: 0 0 10px;
  padding: 20px;
  background-color: #fff;
}

.forumPage .list ul > li > div {
  border-width: 0;
  border-color: var(--black3);
  border-style: dashed;
}

.forumPage .list ul > li:last-child > div {
  border-width: 0;
}

.forumPage .list ul > li .commentContent {
  margin: 10px 0 0;
  padding: 10px 0 0;
  border-radius: 0 15px 15px 15px;
  -webkit-border-radius: 0 0 15px 15px 15px;
  -moz-border-radius: 0 0 15px 15px 15px;
}

.forumPage .list ul > li .utterance p {
  font-size: 18px;
  color: var(--black1);
}

.forumPage .list ul > li .photoGroup {
  margin: 20px 0 0;
}

.forumPage .list ul > li .photoGroup > div {
  max-width: 100%;
  min-width: 120px;
  max-height: 360px;
  padding: 10px;
  overflow: hidden;
  background-color: var(--a_5);
}

.forumPage .list ul > li .photoGroup > div:not(:last-child) {
  margin: 0 10px 0 0;
}

.forumPage .list ul > li .photoGroup > div img {
  width: 100%;
  display: block;
}

.forumPage .list ul > li .photoGroup > div.callOut {
  max-width: initial;
  min-width: initial;
  max-height: initial;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 5px;
  overflow: scroll;
  position: fixed;
  background-color: transparent;
  z-index: 9999;
}

.forumPage .list ul > li .photoGroup > div.callOut:after {
  content: " ";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.3);
  position: fixed;
  display: block;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  -ms-backdrop-filter: blur(2px);
  z-index: 0;
}

.forumPage .list ul > li .photoGroup > div.callOut img {
  margin: 0 0 100px;
  display: block;
  position: relative;
  z-index: 1;
}

.forumPage .list ul > li .reward .icon {
  width: 35px;
}

.forumPage .list ul > li .reward .icon img {
  width: 100%;
}

.forumPage .list ul > li .reward p {
  line-height: 1;
  font-size: 28px;
  font-weight: 700;
  color: var(--b_1);
}

.forumPage .list ul > li .reward span {
  color: var(--black3);
  font-size: 12px;
  font-weight: 300;
  display: block;
}

.forumPage .list ul > li .pubdate p {
  text-align: right;
  font-size: 16px;
  color: var(--black2);
}

.forumPage .list ul > li .statusBar {
  margin: 10px 0 0;
  padding: 10px 0 0;
  background-color: #fff;
  position: relative;
  border-width: 5px 0 0;
  border-color: var(--black4);
  border-style: dotted;
}

.publishEnter {
  top: 110px;
  right: -2px;
  padding: 5px 10px;
  position: fixed;
  background-color: var(--a_1);
  border-radius: 500px 0 0 500px;
  -webkit-border-radius: 500px 0 0 500px;
  -moz-border-radius: 500px 0 0 500px;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  z-index: 99;
}

.publishEnter .icon {
  width: 60px;
  /* height: 60px; */
  overflow: hidden;
}

.publishEnter .icon i {
  font-size: 36px;
  color: #fff;
}

.publishEnter p {
  line-height: 1;
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.publishPage .publish {
  margin: 10px;
  padding: 20px;
  background-color: #fff;
}

.publishPage .utterance {
  max-height: 350px;
}

.publishBtn {
  background-color: var(--b_1);
}

.sketchBox .addPicture {
  width: 80px;
  height: 80px;
  position: relative;
  background-color: var(--black5);
}

.sketchBox .addPicture i {
  font-size: 26px;
  color: var(--black3);
}

.sketchBox .addPicture > input {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0.0000001;
  z-index: 9;
}

.sketchBox .sketchList > div {
  height: 80px;
  margin: 0 10px 10px 0;
  position: relative;
}

.sketchBox .sketchList > div img {
  height: 100%;
}

.sketchBox .sketchList .imgDelete {
  width: 25px;
  height: 25px;
  top: -5px;
  right: -5px;
  position: absolute;
  z-index: 9;
  background-color: var(--r_3);
}

.sketchBox .sketchList .imgDelete i {
  font-size: 14px;
  color: #fff;
}

#previewSketch .promptMain {
  width: 100%;
  max-height: 100vh;
  top: 0;
  background-color: transparent;
}

#previewSketch .promptMain .roundOff {
  background-color: var(--r_3);
}

#previewSketch .promptMain .promptCont {
  padding: 0;
  max-height: 100vh;
}

#previewSketch .promptCont img {
  width: 100%;
}

.publishQuery {
  margin: 0 0 10px;
  padding: 0 0 10px;
}
</style>
